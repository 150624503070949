body {
	font-family: Roboto, sans-serif;
	color: #444;
	font-size: 1.05rem;
	line-height: 1.55rem;
	font-weight: 400;
}

h1 {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 2.75rem;
	line-height: 3.45rem;
	font-weight: 700;
	text-transform: uppercase;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1.9rem;
	line-height: 2.4rem;
	font-weight: 700;
}

h3 {
	margin-top: 1.5em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1.7rem;
	line-height: 2.3rem;
	font-weight: 700;
}

h4 {
	margin-top: 1em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
}

h5 {
	margin-top: 1.375em;
	margin-bottom: 0.75em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1.2rem;
	line-height: 1.7rem;
	font-weight: 700;
}

h6 {
	margin-top: 1.25em;
	margin-bottom: 1em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-transform: uppercase;
}

p {
	margin-top: 0em;
	margin-bottom: 0.75em;
}

a {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #163a59;
	text-decoration: none;
}

a:hover {
	color: #000;
	text-decoration: none;
}

ul {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.25em;
}

ol {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

li {
	margin-bottom: 0.25em;
}

label {
	display: block;
	margin-bottom: 0.25em;
	line-height: 1.25em;
	font-weight: 400;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	padding: 1.25rem 1.5rem;
	border-left: 5px solid #76a009;
	background-color: #e5eef2;
	color: #000;
	font-size: 1.25rem;
	line-height: 1.9rem;
}

.button {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 1.25rem;
	margin-bottom: 0.5rem;
	padding: 0.65rem 1.5rem;
	border-style: solid;
	border-width: 1px;
	border-color: #263996;
	background-color: #263996;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Roboto, sans-serif;
	color: #fff;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover {
	background-color: #ee1b2c;
	color: #fff;
}

.button.secondary {
	border-style: solid;
	border-width: 1px;
	border-color: #263996;
	background-color: #fff;
	color: #263996;
}

.button.secondary:hover {
	background-color: #263996;
	color: #fff;
}

.button.tertiary {
	margin-right: 0rem;
	padding: 0.5rem 1rem 0.35rem;
	background-color: #acadaf;
	color: rgba(0, 0, 0, 0.7);
}

.button.tertiary:hover {
	background-color: #3e3f3d;
	color: #fff;
}

.button.footer-button {
	margin-top: 0.25em;
	padding-bottom: 0.625em;
	background-color: #163a59;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 1em;
	line-height: 1.25em;
}

.button.footer-button:hover {
	background-color: #000;
}

.button.right-sidebar-button {
	font-size: 1rem;
}

.button.header-search-button {
	width: 2.5rem;
	margin-top: 0em;
	margin-right: 0rem;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-radius: 0px;
	background-color: #fff;
	background-image: url('/20181016082423/assets/images/search_blk.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	opacity: 0.65;
	color: transparent;
	font-size: 0.875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.button.add-to-cart {
	max-width: 65%;
	margin-right: 0.5em;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.button.slider-button {
	position: relative;
	margin-top: 0.625em;
	margin-bottom: 0em;
	padding: 0.6rem 1rem 0.6rem 3.125rem;
	border-style: solid;
	border-width: 1px;
	border-color: #263996;
	background-color: hsla(0, 0%, 100%, 0.65);
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-align: left;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.button.slider-button:hover {
	padding-left: 3.5rem;
	background-color: hsla(0, 0%, 100%, 0.8);
	color: #ee1b2c;
}

.container {
	position: relative;
	max-width: 1200px;
	padding-right: 0%;
	padding-left: 0%;
}

.container.footer-container {
	position: relative;
	left: auto;
	top: 0px;
	right: auto;
	bottom: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0px;
	padding-right: 0%;
	padding-left: 0%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: #ececec;
	width: 100%;
}

.nav-section {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	background-color: #fff;
}

.nav-container {
	position: relative;
	max-width: 100%;
}

.nav-link {
	display: inline-block;
	margin-right: 1px;
	padding: 1.25rem 0.25rem 1rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	box-shadow: 0 -3px 0 0 transparent, 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	font-family: Roboto, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.375rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0em;
	text-transform: uppercase;
}

.nav-link:hover {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: 0 -5px 0 0 #f6c952, 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	color: #fff;
}

.nav-link.w--current {
	background-color: rgba(0, 0, 0, 0.2);
	box-shadow: 0 -5px 0 0 #f6c952;
	color: #fff;
}

.nav-link.w--current:hover {
	background-color: rgba(0, 0, 0, 0.35);
	box-shadow: 0 -5px 0 0 #f6c952, 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	color: #fff;
}

.nav-link.not-current {
	background-color: transparent;
	box-shadow: none;
}

.nav-link.not-current:hover {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: 0 -5px 0 0 #f6c952, 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
}

.nav-link.not-current.mobile-link {
	box-shadow: 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
}

.nav-link.not-current.mobile-link:hover {
	box-shadow: 1px 0 0 0 hsla(0, 0%, 100%, 0.25), 0 -5px 0 0 #f6c952;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-left: 1px solid hsla(0, 0%, 100%, 0.5);
}

.logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.75em;
	margin-bottom: 1em;
	margin-left: 0.375em;
	padding-right: 0.25rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	border-bottom-style: none;
	color: #000;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-style: italic;
	font-weight: 700;
}

.logo:hover {
	color: rgba(0, 0, 0, 0.65);
}

.logo.w--current {
	left: -40px;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	margin-left: 0px;
	padding-right: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.logo.logo-header {
	margin-top: 0.125em;
	margin-bottom: 0.125em;
	margin-left: 0px;
}

.logo.logo-footer {
	left: -80px;
}

.footer-section {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	border-top: 5px solid #f6c952;
	background-color: #ececec;
	color: #3e3f3d;
	font-size: 0.9375rem;
	line-height: 1.625em;
}

.row.shop-detail-row {
	margin-bottom: 1.5em;
}

.row.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -100px;
	padding-top: 1.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.row.footer-signoff-row._1 {
	width: auto;
}

.row.footer-signoff-row._1._11 {
	display: none;
}

.row.footer-signoff-row._11 {
	display: none;
}

.row.home-cbox-row-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 4em;
}

.row.home-cbox-row-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 40px;
}

.column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.column.shop-detail-image-col {
	padding-right: 20px;
}

.column.footer-signoff-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.column.footer-signoff-column.right-signoff-column {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.column.column-2 {
	padding-left: 20px;
	border-top: 5px none #ac342f;
	background-color: transparent;
}

.column.column-2 {
	padding-left: 20px;
	border-top: 5px none #ac342f;
	background-color: transparent;
}

.logo-image {
	width: 210px;
	float: left;
}

.logo-image.footer-logo {
	display: block;
	width: 200px;
}

.footer-signoff-section {
	display: block;
	padding-bottom: 1.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: transparent;
	font-size: 0.8rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0rem;
}

.copyright-text h1 {
	font-family: inherit;
	line-height: inherit;
	font-weight: inherit;
	color: inherit;
	display: inline;
	margin: 0px;
	padding: 0px;
	font-size: inherit;
	text-transform: none;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem 0.25rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-top: 0.125em;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.footer-signoff-grip {
	display: none;
	width: 130px;
	height: 28px;
	margin-top: 2px;
	padding-left: 32px;
	float: right;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	border-bottom-style: none;
	border-bottom-width: 0px;
	background-size: 130px 28px;
	background-repeat: no-repeat;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #606060;
	font-size: 8px;
	line-height: 9px;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	color: #606060;
}

.footer-column-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0.75rem;
	margin-right: 1rem;
	margin-bottom: 0.75rem;
	padding-right: 0rem;
	clear: both;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.footer-column-wrap.footer-social-column {
	max-width: 200px;
}

.footer-column-wrap.footer-updates-column {
	max-width: 45%;
	margin-top: -0.75rem;
	margin-bottom: -0.75rem;
	padding-top: 1.5rem;
	padding-right: 1.5rem;
	padding-bottom: 2.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-left: 1px none rgba(0, 0, 0, 0.1);
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.5)), color-stop(60%, hsla(0, 0%, 100%, 0.4)), color-stop(95%, hsla(0, 0%, 100%, 0)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.4) 60%, hsla(0, 0%, 100%, 0) 95%);
	box-shadow: 0 -10px 0 0 rgba(0, 0, 0, 0.5);
}

.footer-column-title {
	width: 200px;
	margin-top: 0.5em;
	margin-bottom: 0.375em;
	padding-top: 0.125em;
	font-family: Roboto, sans-serif;
	color: #394486;
	font-size: 1.25rem;
	line-height: 1.25em;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.footer-column-title.footer-notifications-title {
	font-family: Roboto, sans-serif;
	color: #394486;
	font-size: 1.25rem;
	line-height: 1.25em;
}

.footer-column-title.title1 {
	width: 300px;
}

.footer-column-title.footer-title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 140px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #3e3f3d;
	font-size: 0.8rem;
}

.footer-column-title.footer-title._1 {
	display: none;
}

.header-content-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1em;
	padding-bottom: 0.75rem;
	float: right;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.header-contents-wrap {
	position: relative;
	background-color: transparent;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 0em 0.125rem 1.5rem;
	padding-left: 0px;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 400;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	background-color: #263996;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-wrap.pagination-sort {
	position: relative;
	display: inline-block;
	text-align: left;
}

.form.sort-by-form {
	position: relative;
}

.form.newsletter-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.form.newsletter-form._1 {
	display: none;
}

.form.newsletter-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.form.newsletter-form._1 {
	display: none;
}

.form-field-label.newsletter-form-field-label {
	position: absolute;
	opacity: 0;
}

.form-field-label.newsletter-form-field-label {
	position: absolute;
	top: -25px;
	margin-bottom: 0.5em;
	opacity: 1;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
}

.form-field.right-sidebar-field {
	width: 100%;
	margin-top: 0.375em;
	margin-bottom: 1.25em;
	padding-right: 4px;
	padding-left: 8px;
}

.form-field.right-sidebar-field.miles-from {
	width: 52%;
}

.form-field.right-sidebar-field.zip-code {
	width: 45%;
}

.form-field.sort-by-dropdown {
	height: 2.25rem;
	margin-bottom: 0em;
	padding-right: 6px;
	padding-left: 6px;
}

.form-field.header-search-field {
	width: 120px;
	height: 2.25rem;
	margin-bottom: 0em;
	border-style: none;
	border-width: 0px;
	border-color: rgba(0, 0, 0, 0.15);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #3e3f3d;
	font-size: 0.75rem;
}

.form-field.header-search-field:focus {
	width: 160px;
	border: 0px none transparent;
	background-color: #fff;
}

.form-field.header-search-field::-webkit-input-placeholder {
	color: #797676;
}

.form-field.header-search-field:-ms-input-placeholder {
	color: #797676;
}

.form-field.header-search-field::-ms-input-placeholder {
	color: #797676;
}

.form-field.header-search-field::placeholder {
	color: #797676;
}

.form-field.shop-detail-form-field {
	width: 100%;
	margin-bottom: 0.25em;
}

.form-field.newsletter-form-field {
	display: block;
	width: 350px;
	height: 45px;
	margin-bottom: 10px;
	padding-right: 1.25em;
	padding-left: 1.25em;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(68, 68, 68, 0.25) rgba(68, 68, 68, 0.25) #263996;
	border-radius: 0em;
	background-color: transparent;
	box-shadow: none;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 1rem;
}

.form-field.newsletter-form-field:focus {
	background-color: #fff;
	color: #172a38;
}

.form-field.newsletter-form-field {
	display: block;
	width: 580px;
	height: 45px;
	margin-bottom: 40px;
	padding-right: 1.25em;
	padding-left: 1.25em;
	border-style: solid solid none;
	border-width: 1px;
	border-color: rgba(68, 68, 68, 0.25) rgba(68, 68, 68, 0.25) #394486;
	border-radius: 0em;
	background-color: #fff;
	box-shadow: none;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 1rem;
}

.form-field.newsletter-form-field:focus {
	background-color: #fff;
	color: #172a38;
}

.form-field.newsletter-form-field.comments-field {
	height: 120px;
}

.form-success {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	padding: 1.25rem;
	background-color: #1f8927;
	color: #fff;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #d11616;
	color: #fff;
}

.secondary-nav-list {
	display: none;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item {
	margin-right: 0.75rem;
	margin-bottom: 0rem;
	margin-left: 0.75rem;
	float: left;
	letter-spacing: 0.01rem;
}

.secondary-nav-list-item.first {
	margin-left: 0rem;
	border-left-style: none;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.left-nav-column {
	padding-right: 20px;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
	border-top: 1px solid #76a009;
	box-shadow: 0 1px 0 0 #e6e6e6;
}

.left-nav-list-item {
	margin-bottom: 0em;
	box-shadow: 0 -1px 0 0 #e6e6e6;
}

.left-nav-list-link {
	display: block;
	padding: 0.6rem 0.75rem 0.6rem 0.5rem;
	border-left: 3px solid transparent;
	color: #444;
	font-size: 1.1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.left-nav-list-link:hover {
	border-left-color: #f6c952;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #263996;
}

.left-nav-list-link.w--current {
	border-left-color: #f6c952;
	background-color: rgba(38, 57, 150, 0.15);
	color: #263996;
}

.left-nav-list-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-list-link.current-path-not-current-page.w--current {
	border-left-color: #f6c952;
	background-color: transparent;
	color: #263996;
	font-weight: 400;
}

.left-nav-nested-list {
	display: block;
	overflow: hidden;
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 0px 0em 0px 0.75em;
	border-top: 1px solid #e6e6e6;
	box-shadow: inset 0 -1px 0 0 #e6e6e6;
}

.left-nav-nested-list-item {
	margin-bottom: 0em;
	box-shadow: 0 1px 0 0 #e6e6e6;
}

.grandchild-left-nav-link {
	display: block;
	padding: 0.6rem 1.25rem 0.6rem 0.5rem;
	border-left: 2px solid transparent;
	color: #444;
	font-size: 0.95rem;
	line-height: 1.25rem;
	font-weight: 400;
	letter-spacing: 0.03em;
}

.grandchild-left-nav-link:hover {
	border-left-color: #f6c952;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #263996;
}

.grandchild-left-nav-link.w--current {
	border-left-color: #f6c952;
	background-color: rgba(38, 57, 150, 0.15);
	color: #263996;
}

.grandchild-left-nav-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.grandchild-left-nav-link.current-path-not-current-page.w--current {
	border-left-color: #f6c952;
	background-color: transparent;
	color: #263996;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	padding-top: 0.75em;
	padding-right: 3px;
	padding-left: 3px;
	border-top: 2px solid #f6c952;
	font-size: 1rem;
	line-height: 1.5rem;
}

.sidebar-section-title-h4 {
	margin-top: 0.25em;
	margin-bottom: 0.5em;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.sidebar-list {
	padding-left: 0px;
}

.sidebar-list-item {
	margin-bottom: 0.5em;
	padding-left: 0px;
}

.section-wrap.inside-content-section {
	background-color: rgba(127, 168, 189, 0.2);
	background-image: url('/20181016082423/assets/images/lined-paper-wht.png');
	background-position: 0px 0px;
	background-size: 150px;
}

.secondary-nav-icon {
	width: 1.625rem;
	height: 1.625rem;
	margin-right: 0.375rem;
	padding: 6px;
	float: left;
	background-color: #5b636b;
}

.secondary-nav-icon.idea-icon {
	width: 2em;
	height: 2em;
	margin-right: 0.4125em;
	padding: 7px 7px 10px;
	border-radius: 50%;
	background-color: #163a59;
}

.secondary-nav-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.secondary-nav-text.phone-text {
	padding-top: 0.125em;
}

.secondary-nav-text.indicator-text {
	position: relative;
	min-width: 2em;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	padding: 4px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: none;
	font-size: 0.875em;
	line-height: 1.5em;
	text-align: center;
	letter-spacing: 0em;
}

.secondary-nav-list-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: rgba(0, 0, 0, 0.75);
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

.secondary-nav-list-link:hover {
	-webkit-transform: translate(0px, -3px);
	-ms-transform: translate(0px, -3px);
	transform: translate(0px, -3px);
	color: #64802c;
}

.slider-section {
	position: relative;
	top: 175px;
	margin-top: 5px;
	background-color: #f8f9fa;
}

.slider {
	display: block;
	height: 45vw;
	max-height: 540px;
	max-width: 100%;
	min-height: 150px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.slider-arrow {
	top: 50%;
	bottom: auto;
	width: 5rem;
	height: 6rem;
	background-color: transparent;
	opacity: 0.6;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	color: #83888d;
	font-size: 3rem;
}

.slider-arrow:hover {
	background-color: #394486;
	opacity: 1;
	color: #fff;
}

.slider-arrow:focus {
	background-color: transparent;
	opacity: 1;
	color: #163a59;
}

.slider-indicator {
	overflow: hidden;
	height: 0.25em;
	margin-bottom: 1em;
	padding-top: 0rem;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 1.5rem;
	line-height: 0.5em;
}

.home-section {
	margin-top: 170px;
	background-color: #f3f5f7;
}

.home-image-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 25vw;
	max-height: 320px;
	min-height: 140px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 5px solid #163a59;
	background-color: #f3f5f7;
	background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-image-link:hover {
	opacity: 0.85;
}

.home-image-link.large-image-link {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 35%;
	-ms-flex-preferred-size: 35%;
	flex-basis: 35%;
	border-top-style: none;
	border-top-color: #ac342f;
}

.home-image-link.large-image-link.large-image-1 {
	border-top-style: none;
	border-top-color: #ac342f;
}

.home-image-link.home-cbox-image-1 {
	border-top-color: #76a009;
}

.home-image-link.home-cbox-image-2 {
	border-top-color: #76a009;
}

.home-image-link.home-cbox-image-3 {
	border-top-color: #76a009;
}

.home-image-link.home-cbox-image-4 {
	border-top-color: #76a009;
}

.home-content-box-wrap {
	position: relative;
	border-top: 5px solid #ac342f;
	background-color: #fff;
}

.home-content-box-wrap.intro-content-box-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-top-style: none;
	background-color: transparent;
}

.home-content-box-wrap.hero-cbox-wrap {
	border-top-color: #f6c952;
	background-color: transparent;
	width: 100%;
}

.home-content-box-title {
	margin-top: 0rem;
	margin-bottom: 30px;
	padding-top: 10px;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 1.625em;
	line-height: 1.125em;
	text-transform: uppercase;
}

.home-content-box-title.hero-cbox-title {
	font-size: 1.75em;
}

.home-content-box-title.hero-cbox-title:hover {
	color: #394486;
}

.home-content-box-title.intro-cbox-title {
	padding-top: 0.125em;
	font-size: 2.25em;
	line-height: 1.125em;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.home-cbox-learn-more {
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 500;
	display: inline-block;
	margin-top: 0rem;
	padding-top: 0.38rem;
	padding-bottom: 0.25rem;
	padding-left: 0rem;
	border-bottom: 1px solid transparent;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 1.1rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 0.03rem;
	text-transform: uppercase;
}

.home-cbox-learn-more:hover {
	padding-left: 0rem;
	border-bottom: 1px solid #ee1b2c;
	color: #ee1b2c;
}

.home-cbox-learn-more.intro-cbox-learn-more {
	position: relative;
	padding: 1rem 2rem;
	border-bottom-style: none;
	background-color: #fff;
	color: #263996;
	font-size: 1em;
	font-weight: 500;
	letter-spacing: 0.07em;
	text-shadow: none;
}

.home-cbox-learn-more.intro-cbox-learn-more:hover {
	border-bottom-style: none;
	background-color: #263996;
	color: #fff;
}

.home-cbox-learn-more.intro-cbox-learn-more.button2 {
	display: block;
	width: 350px;
	height: 45px;
	padding: 0.5rem 1rem;
	border-style: solid;
	border-width: 1px;
	border-color: #394486 #394486 #1b4289;
	background-color: transparent;
	color: #263996;
	line-height: 1.7rem;
	text-align: center;
}

.home-cbox-learn-more.intro-cbox-learn-more.button2:hover {
	border-color: #263996 #1b4289 #1b4289;
	background-color: #1b4289;
	color: #fff;
}

.home-cbox-learn-more.intro-cbox-learn-more.button2.button3 {
	margin-bottom: 10px;
}

.home-cbox-learn-more.intro-cbox-learn-more.button2.button3:hover {
	background-color: #1b4289;
}

.home-cbox-learn-more.learn-tabmobile {
	display: none;
}

.home-cbox-learn-more.button-new {
	position: relative;
	margin-top: 2.5rem;
}

.slider-image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-caption-outer-wrap {
	position: relative;
	top: 50%;
	display: block;
	max-width: 70%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1rem;
	padding-bottom: 2rem;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
	color: #fff;
	text-align: left;
}

.shop-item-wrap {
	margin-bottom: 20px;
	padding-bottom: 10px;
}

.shop-item-wrap.flex {
	width: 31.33%;
	margin-right: 1%;
	margin-left: 1%;
}

.shop-item-wrap.flex.item-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 98%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border-bottom: 1px solid #e6e6e6;
}

.product-image-link {
	display: block;
	margin-bottom: 0.75em;
	padding: 0px;
	text-align: center;
}

.product-image-link.list-view-link {
	max-width: 25%;
}

.product-info-image {
	display: inline-block;
}

.product-info-title {
	margin-top: 0.5em;
	font-size: 1.375em;
	line-height: 1.375em;
}

.product-info-abstract {
	font-size: 0.875em;
	line-height: 1.5em;
}

.product-info-price {
	display: inline-block;
	margin-right: 0.5em;
	font-size: 1em;
	line-height: 1.25em;
	font-weight: 600;
	text-transform: uppercase;
}

.product-info-price.old-price {
	color: #a3a3a3;
	text-decoration: line-through;
}

.product-info-price.sale-price {
	color: #bf6034;
}

.inside-page-header-content-wrap {
	margin-bottom: 1.5em;
	padding-bottom: 0em;
	border-bottom: 1px solid #e6e6e6;
}

.inside-page-header-section {
	position: relative;
	display: block;
	height: 80px;
	background-color: #112d45;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.22)), to(rgba(0, 0, 0, 0.22)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22));
	color: #3e3f3d;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 1280px;
	margin-top: 97px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1.5rem;
	padding-bottom: 4rem;
	border-top: 7px solid #f6c952;
	background-color: #fff;
}

.main-content-wrap.nested-content-wrap {
	margin-right: 0.5em;
}

.header-menu-inside-wrap {
	display: block;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
}

.intro-text-style {
	font-size: 1.2rem;
	line-height: 1.8rem;
}

.home-cbox-text-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1.125em;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 1.0625rem;
	line-height: 1.5em;
}

.home-cbox-text-wrap.hero-cbox-text-wrap {
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	font-size: 1.25rem;
	line-height: 1.5em;
}

.home-cbox-text-wrap.intro-cbox-text-wrap {
	display: block;
	max-width: 70%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1em;
	padding-bottom: 1em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: Khand;
	color: hsla(0, 0%, 100%, 0.85);
	font-size: 1.5rem;
	line-height: 1.5em;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.03em;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.home-cbox-text-wrap.box-wrap {
	width: 476.5px;
	height: 398px;
	padding-top: 10px;
	padding-bottom: 8rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.home-cbox-text-wrap.hero-cbox-text-wrap {
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	font-size: 1.25rem;
	line-height: 1.5em;
}

.home-cbox-text-wrap.hero-cbox-text-wrap.contact-box {
	padding-top: 1em;
}

.slider-caption-title {
	display: inline-block;
	padding: 0;
	background-color: hsla(0, 0%, 100%, 0.65);
	box-shadow: 20px 0 0 0 hsl(0deg 0% 100% / 65%), -20px 0 0 0 hsl(0deg 0% 100% / 65%);
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 3.2rem;
	line-height: 4rem;
	font-weight: 700;
	letter-spacing: 0;
	text-transform: uppercase;
	margin-bottom: 1rem;
}

.footer-social-icons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1em;
	padding-left: 1px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-social-icon-link {
	margin-right: 8px;
	margin-bottom: 8px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25);
	background-color: #fff;
	opacity: 0.7;
}

.footer-social-icon-link:hover {
	border-color: rgba(0, 0, 0, 0.2);
	background-color: #fff;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(127, 168, 189, 0.04)), to(rgba(127, 168, 189, 0.04)));
	background-image: linear-gradient(180deg, rgba(127, 168, 189, 0.04), rgba(127, 168, 189, 0.04));
	opacity: 1;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.footer-social-icon-link:focus {
	border-color: #000;
}

.footer-social-icon-link._1 {
	display: none;
}

.footer-social-icon-image {
	width: 32px;
	height: 32px;
	padding: 2px;
}

.footer-newsletter-wrap {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.home-cbox-dynamic-feed-item-wrap {
	margin-bottom: 0.75rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-bottom: 1px solid rgba(40, 49, 59, 0.15);
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.home-cbox-dynamic-feed-date {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.home-cbox-dynamic-feed-item-title {
	margin-top: 0em;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.left-nav-list-content-wrap {
	display: block;
	height: auto;
}

.footer-link {
	color: #3e3f3d;
	font-size: 0.95rem;
	font-weight: 700;
	text-transform: uppercase;
}

.footer-link:hover {
	box-shadow: 0 1px 0 0 #163a59;
	color: #000;
}

.footer-link:focus {
	box-shadow: 0 1px 0 0 #163a59;
}

.footer-link.signoff-link {
	font-size: 0.8rem;
}

.link {
	border-bottom: 1px solid transparent;
	color: #263996;
	font-weight: 500;
}

.link:hover {
	border-bottom-color: #797676;
	color: #263996;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.pagination-link {
	margin: 0.5em 0.25em;
	float: left;
	font-weight: 700;
	text-transform: uppercase;
}

.link.pagination-link.next {
	float: right;
}

.link.h3-link {
	font-family: Roboto, sans-serif;
	font-weight: 700;
}

.link.h3-link:hover {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.link.content-box-title-link {
	border-bottom-style: none;
	border-bottom-color: transparent;
	font-family: Roboto, sans-serif;
	color: #263996;
}

.link.content-box-title-link:hover {
	border-bottom-style: none;
	border-bottom-color: #797676;
	color: #263996;
	text-decoration: none;
}

.link.content-box-title-link.intro-cbox-title-link {
	display: inline-block;
	padding-top: 0.125em;
	border-bottom-style: solid;
	border-bottom-color: transparent;
	font-family: Roboto, sans-serif;
	color: #fff;
	text-shadow: none;
}

.link.content-box-title-link.intro-cbox-title-link:hover {
	border-bottom-color: transparent;
	color: #fff;
	text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.link.h2-link {
	font-family: Roboto, sans-serif;
	font-weight: 700;
}

.link.h2-link:hover {
	color: #263996;
}

.link.breadcrumb-link {
	display: inline-block;
	color: #797676;
	font-weight: 400;
}

.link.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #3e3f3d;
	font-weight: 400;
}

.link.h4-link {
	font-family: Roboto, sans-serif;
	font-weight: 700;
}

.link.h4-link:hover {
	color: #263996;
}

.link.h5-link {
	font-weight: 700;
}

.link.h5-link:hover {
	color: #263996;
}

.link.h6-link {
	font-family: Roboto, sans-serif;
	font-weight: 700;
}

.link.h6-link:hover {
	color: #263996;
}

.link.h1-link {
	border-bottom-color: transparent;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-weight: 700;
}

.link.h1-link:hover {
	border-bottom-color: #797676;
	color: #263996;
}

.link.content-box-title-link {
	border-bottom-style: none;
	border-bottom-color: transparent;
	font-family: Roboto, sans-serif;
	color: #263996;
}

.link.content-box-title-link:hover {
	border-bottom-style: none;
	border-bottom-color: #797676;
	color: #394486;
	text-decoration: none;
}

.pagination-middle-wrap {
	display: inline-block;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.pagination-middle-text {
	display: inline-block;
	margin-right: 0.5em;
	margin-left: 0.5em;
}

.pagination-middle-select {
	display: inline-block;
	width: auto;
	max-height: 2.25rem;
	margin-bottom: 0px;
	padding: 0px 0px 0px 2px;
}

.pagination-wrap {
	margin-bottom: 1.5em;
	padding-top: 0.75em;
	padding-bottom: 0.75em;
	border-top: 1px solid #e6e6e6;
	border-bottom: 1px solid #e6e6e6;
	font-size: 0.875em;
	line-height: 1.25em;
	text-align: center;
}

.pagination-wrap.flex-pagination {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1.5em;
	padding-right: 1%;
	padding-left: 1%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: #e6e6e6;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.shop-item-text-wrap.list-view-text-wrap {
	margin-left: 1em;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.loc-view-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-view-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 48%;
	padding-top: 0.5em;
	padding-right: 0.25em;
	padding-bottom: 0.5em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: solid;
	border-width: 2px;
	border-color: rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	opacity: 0.55;
	-webkit-transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	color: #000;
	font-weight: 700;
}

.loc-view-button-wrap:hover {
	border-color: rgba(0, 0, 0, 0.25);
	opacity: 1;
}

.loc-view-button-wrap.w--current {
	border-color: #000;
	opacity: 0.7;
}

.loc-view-button-wrap.w--current:hover {
	opacity: 1;
}

.loc-miles-from-zip-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-list-section-title {
	margin-top: 0em;
}

.loc-select-type-form-wrap {
	position: relative;
}

.loc-type-form {
	position: relative;
}

.loc-type-form-checkbox {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-bottom: 0.75em;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	line-height: 1.375em;
}

.loc-type-form-checkbox.select-all-wrap {
	font-weight: 700;
}

.loc-type-form-checkbox.sidebar-checkbox {
	width: 100%;
	margin-bottom: 0.5em;
}

.loc-type-form-checkbox.sidebar-checkbox.select-all {
	margin-top: 0.75em;
	font-weight: 600;
}

.loc-type-form-checkbox-box {
	position: relative;
	width: 1.5em;
	height: 1.5em;
	margin-top: 0px;
	margin-right: 0.5em;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.loc-type-form-checkbox-box.select-all-checkbox {
	width: 1.4125em;
	height: 1.4125em;
	margin-right: 0.5em;
}

.loc-type-form-checkbox-label {
	position: relative;
	float: none;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.loc-type-form-checkbox-label.select-all-label {
	font-weight: 700;
	text-transform: uppercase;
}

.loc-type-form-checkboxes-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.75em;
	margin-bottom: 1.25em;
	padding-top: 1em;
	padding-bottom: 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	border-bottom: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-type-form-checkbox-image {
	position: relative;
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin-right: 0.5em;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-sidebar-search-reveal-button {
	position: relative;
	display: none;
	margin-bottom: 0em;
	padding: 0.75em 0.5em 0.75em 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	font-size: 1.125em;
	line-height: 1.25em;
	font-weight: 600;
	cursor: pointer;
}

.loc-sidebar-search-reveal-button:active {
	color: #777;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.right-sidebar-refine-search-button-text {
	margin-top: 0em;
	margin-bottom: 0em;
	margin-left: 0.25em;
	font-size: 1.125em;
	line-height: 1.25em;
}

.loc-refine-search-checkbox-wrap {
	padding-top: 0.5em;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-view-button-text {
	margin-left: 0.25em;
}

.loc-view-button-image {
	position: relative;
	display: block;
	width: 1.125rem;
	height: 1.375rem;
}

.pagination-left-side-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.pagination-view-by-icons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 0.5rem;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.pagination-middle-group-wrap {
	display: inline-block;
}

.inside-page-content-wrap-no-sidebars {
	margin-top: 0rem;
	margin-right: 0%;
	margin-left: 0%;
}

.nav-drop-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px solid hsla(0, 0%, 100%, 0.5);
}

.nav-drop-wrap._1 {
	display: none;
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	box-shadow: 0 -3px 0 0 transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 100%, 0.5);
}

.nav-drop-toggle:hover {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: 0 -5px 0 0 #ac342f;
	color: hsla(0, 0%, 100%, 0.75);
}

.nav-drop-toggle.w--open {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: 0 -5px 0 0 #f6c952;
	color: #fff;
}

.nav-drop-toggle.w--open:hover {
	background-color: rgba(0, 0, 0, 0.25);
}

.nav-drop-icon {
	margin-right: 1rem;
	font-size: 0.6875em;
}

.nav-drop-list {
	display: none;
	overflow: hidden;
}

.nav-drop-list.w--open {
	left: 0px;
	top: 100%;
	padding: 0.25rem 0em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #f6c952;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.25)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
	color: #fff;
}

.nav-drop-list.open-left.w--open {
	background-color: #f6c952;
}

.nav-drop-list-link {
	margin-bottom: 0.125rem;
	padding: 0.75rem 2rem 0.75rem 0.75rem;
	background-color: #263996;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.875rem;
	line-height: 1.1rem;
	font-weight: 400;
}

.nav-drop-list-link:hover {
	background-color: #263996;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	color: #fff;
}

.nav-drop-list-link.w--current {
	box-shadow: inset 3px 0 0 0 #163a59;
	color: #fff;
}

.nav-drop-toggle-link {
	width: 100%;
	padding: 1.25rem 0rem 1rem;
	border-top: 1px solid #000;
	box-shadow: none;
	font-family: Roboto, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
}

.nav-drop-toggle-link:hover {
	box-shadow: 0 -5px 0 0 #f6c952;
	color: #fff;
}

.nav-drop-toggle-link.w--current {
	border-top-color: #f6c952;
	background-color: rgba(0, 0, 0, 0.2);
	box-shadow: 0 -5px 0 0 #f6c952;
}

.nav-drop-toggle-link.w--current:hover {
	box-shadow: 0 -5px 0 0 #f6c952;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1140px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.pagination-view-by-icon-image {
	padding: 4px;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.shop-first-heading {
	margin-top: 0em;
}

.shop-header-footer-wrap {
	margin-bottom: 1.5em;
}

.secondary-header-item-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1rem;
	margin-left: 1rem;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	font-family: Khand;
	color: #3e3f3d;
	font-size: 1.25rem;
	line-height: 1em;
	font-weight: 700;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.secondary-header-item-wrap:hover {
	color: #163a59;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.25rem;
	margin-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.secondary-header-item-cta-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	margin-left: 1rem;
	padding: 0.75em 1.5rem 0.61em 3em;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	background-color: #3e3f3d;
	background-image: url('/20181016082423/assets/images/speech-bubble.svg');
	background-position: 21px 50%;
	background-size: 22px 22px;
	background-repeat: no-repeat;
	font-family: Khand;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.secondary-header-item-cta-link:hover {
	background-color: #163a59;
	color: #fff;
}

.secondary-header-item-cta-link:focus {
	background-color: #3e3f3d;
}

.header-search-wrap {
	display: none;
	margin-bottom: 0px;
	margin-left: 1.25rem;
}

.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	background-color: #fff;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
}

.shop-detail-tabs-wrap {
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

.shop-detail-related-items-title {
	margin-top: 0em;
}

.shop-detail-related-items-wrap {
	padding-top: 1.5em;
	border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.shop-detail-tabs-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.shop-detail-tab-link {
	margin-right: 0.5%;
	padding: 0.75em 0.75rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #e6e6e6;
	color: #000;
	font-weight: 500;
}

.shop-detail-tab-link:hover {
	color: #163a59;
}

.shop-detail-tab-link.w--current {
	background-color: #163a59;
	color: #fff;
}

.shop-detail-tabs-content {
	padding: 1em 0.75rem;
}

.shop-detail-attributes-wrap {
	margin-bottom: 1.5em;
	padding: 1em;
	border: 1px solid #e6e6e6;
}

.shop-detail-lightbox-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid #e6e6e6;
	text-align: center;
}

.shop-detail-text.product-price {
	font-size: 1.5em;
	line-height: 1.25em;
}

.shop-detail-form-section {
	margin-top: 0.75em;
	margin-bottom: 0.75em;
	padding-top: 0.5em;
	border-top: 1px solid #e6e6e6;
}

.shop-detail-form-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.slider-caption-inner-wrap {
	margin-bottom: 0.5rem;
	padding-top: 0.25rem;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
}

.slider-caption-heading {
	display: inline-block;
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	padding-top: 0.6em;
	padding-bottom: 0.6rem;
	background-color: hsla(0, 0%, 100%, 0.65);
	box-shadow: 20px 0 0 0 hsla(0, 0%, 100%, 0.65), -20px 0 0 0 hsla(0, 0%, 100%, 0.63);
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 500;
	letter-spacing: 0rem;
	text-shadow: none;
}

.home-content-box-section-wrap {
	padding-top: 3em;
	padding-bottom: 0em;
	background-color: rgba(127, 168, 189, 0.2);
	background-image: url('/20181016082423/assets/images/lined-paper-wht.png');
	background-position: 0px 0px;
	background-size: 150px;
}

.slider-button-arrow-span {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2.25rem;
	height: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	background-color: hsla(0, 0%, 100%, 0.4);
	background-image: url('/20181016082423/assets/images/icon_arrow2_right_blk_50.svg');
	background-position: 50% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 0.85rem;
}

.intro-cbox-section {
	margin-top: 4em;
	margin-bottom: 3em;
	padding-top: 3em;
	padding-bottom: 3em;
	background-color: #000000;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.footer-flex-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 1200px;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 0.75rem;
	padding-bottom: 0.5rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.footer-signoff-acrobat-link {
	margin-right: 2.5em;
}

.acrobat-reader-image {
	width: 135px;
	height: 34px;
}

.paragraph {
	font-family: Roboto, sans-serif;
	color: #fff;
	font-size: 1.4rem;
	font-weight: 400;
	text-shadow: none;
}

.slider-image-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.25)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
}

.footer-phone-span {
	color: #3e3f3d;
}

.footer-phone-span._1 {
	display: none;
}

.footer-contact-wrap {
	width: 50%;
	padding-right: 1.5rem;
}

.footer-contact-wrap .cms-website-content-region a {
	border-bottom: 1px solid transparent;
}

.footer-contact-wrap.footer-license {
	width: 100%;
	margin-right: 8rem;
	text-align: left;
}

.footer-contact-wrap.logo-footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 32%;
	margin-right: 0px;
	padding-right: 1.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.footer-contact-wrap.quick-links {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	padding-right: 0rem;
	padding-left: 40px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.footer-contact-wrap.contact-wrap {
	width: 40%;
	margin-right: 0rem;
	padding-right: 0rem;
}

.footer-social-block {
	width: 50%;
	padding-right: 0rem;
	padding-left: 40px;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.footer-social-block.footer-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 20%;
	margin-right: 20px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.footer-social-block.footer-block.right-block {
	width: 80%;
}

.footer-notifications-image {
	width: 6.5vw;
	height: 6.5vw;
	max-height: 4rem;
	max-width: 4rem;
	margin: 1rem 3vw;
	padding: 0px;
	float: left;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
}

.content-image-example {
	width: 300px;
	margin-bottom: 30px;
	margin-left: 30px;
	float: right;
}

.shop-detail-dimensions-label {
	min-width: 75px;
	float: left;
	font-weight: 700;
}

.footer-quick-link {
	display: inline-block;
	border-bottom: 1px solid transparent;
	color: #3e3f3d;
	line-height: 1.15rem;
}

.footer-quick-link:hover {
	border-bottom: 1px solid #797676;
}

.footer-quick-link.w--current {
	border-bottom-color: #797676;
	line-height: 1.625em;
}

.great-gchild-left-nav-link {
	display: block;
	padding: 0.5rem 1.25rem 0.4375em 0.5rem;
	border-left: 2px solid transparent;
	color: #444;
	font-size: 0.875rem;
	line-height: 1.2rem;
	font-weight: 400;
	letter-spacing: 0.03em;
}

.great-gchild-left-nav-link:hover {
	border-left-color: #f6c952;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #263996;
}

.great-gchild-left-nav-link.w--current {
	border-left-color: #f6c952;
	background-color: rgba(38, 57, 150, 0.15);
	color: #263996;
}

.great-gchild-left-nav-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.sidebar-section-title-h3 {
	margin-top: 0.25em;
	margin-bottom: 0.5em;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.sidebar-section-title-h2 {
	margin-top: 0.25em;
	margin-bottom: 0.5em;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.link-block {
	margin-top: 10px;
	padding: 0.75rem 1.5rem;
	background-color: #263996;
	color: #fff;
	text-transform: uppercase;
}

.link-block:hover {
	background-color: #ee1b2c;
	color: #fff;
}

.heading {
	margin-top: 0rem;
	margin-bottom: 0em;
	padding-top: 0em;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 1.75rem;
	font-weight: 400;
	text-align: right;
}

.text-block {
	display: none;
	color: #263996;
	font-size: 2.6rem;
	line-height: 2.6rem;
	font-weight: 500;
}

.div-block-2 {
	margin-bottom: 0px;
	text-align: right;
}

._1 {
	display: none;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.div-block-3 {
	display: none;
}

.h2-heading {
	margin-top: 10px;
	padding-top: 10px;
	padding-bottom: 20px;
	padding-left: 0rem;
	border-top: 5px none #ac342f;
	background-color: hsla(0, 0%, 100%, 0.8);
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 1.5rem;
	line-height: 1.125em;
	font-weight: 500;
	text-transform: uppercase;
}

.newsletter-text-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 2vw;
	margin-bottom: 0px;
	padding-top: 10px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.newsletter-form-field-wrap {
	position: relative;
	margin-right: 0px;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.form-success-2 {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #1e8631;
	color: #fff;
}

.newsletter-form-wrap {
	margin-bottom: 10px;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.newsletter-form-wrap.left-wrap {
	margin-bottom: -33px;
	padding-top: 0px;
	padding-bottom: 0px;
}

.home-content-box {
	max-width: 25%;
	margin-bottom: 1em;
	padding-right: 1rem;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}

.home-content-box.newsletter-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 396.7890625px;
	max-width: 100%;
	padding-right: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 5px solid #f6c952;
	background-color: #fff;
}

.home-content-box.newsletter-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 396.7890625px;
	max-width: 100%;
	padding-right: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 5px solid #ac342f;
	background-color: #fff;
}

.home-content-box.newsletter-column._1 {
	display: none;
}

.form-error-2 {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #d11616;
	color: #fff;
}

.button-2 {
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 0.5em 1.3em;
	border-radius: 3em;
	background-color: #000;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05em;
	text-decoration: none;
}

.button-2:hover {
	background-color: #549fc4;
	color: #fff;
}

.button-2.newsletter-form-button {
	height: 45px;
	min-width: 6.5rem;
	margin-top: 0em;
	margin-bottom: 0em;
	padding: 1em 0rem;
	background-color: #1e68bd;
	font-size: 0.75rem;
	text-transform: uppercase;
}

.button-2.newsletter-form-button:hover {
	background-color: #144c8c;
}

.heading-2 {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	font-family: Roboto, sans-serif;
	color: #444;
	font-weight: 400;
}

.text-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.image-2 {
	margin-top: 0.5rem;
}

.paragraph-1 {
	width: 350px;
}

.div-block-4 {
	padding-top: 0.5rem;
}

.paragraph-4 {
	width: 350px;
	margin-top: -10px;
	margin-bottom: 0em;
	padding-top: 0px;
	line-height: 2rem;
	font-weight: 400;
}

.column-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.div-block-5 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 10px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.text-block-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.heading-3 {
	width: 350px;
	margin-top: 0em;
	margin-bottom: 0.125em;
	padding-top: 0em;
	padding-bottom: 0px;
	font-family: Roboto, sans-serif;
	color: #03385a;
	font-size: 1.1rem;
	font-weight: 400;
}

.link-2 {
	line-height: 1.55rem;
	font-weight: 400;
	text-decoration: underline;
}

.link-3 {
	font-weight: 400;
	text-decoration: underline;
}

.paragraph-5 {
	width: 350px;
	margin-bottom: 2.65rem;
}

.paragraph-6 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: -25px;
	padding-bottom: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.div-block-6 {
	position: absolute;
	left: 0%;
	top: 3%;
	right: 0%;
	bottom: auto;
	z-index: 1;
	display: none;
	background-color: #fff;
	text-align: center;
}

.image-4 {
	position: static;
	width: 320px;
	text-align: center;
}

.div-block-7 {
	position: absolute;
	left: auto;
	top: auto;
	right: auto;
	bottom: 0px;
	z-index: 2;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 420px;
	height: 200px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-image: url('/20181016082423/assets/images/logo_0.png');
	background-position: 50% 50%;
	background-size: cover;
	-o-object-fit: cover;
	object-fit: cover;
}

.div-block-8 {
	position: relative;
	left: auto;
	top: 0%;
	right: auto;
	bottom: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: 200px;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	text-align: center;
	-o-object-fit: fill;
	object-fit: fill;
}

.container-2 {
	position: relative;
	left: auto;
	right: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.div-block-9 {
	position: absolute;
	bottom: 0px;
	z-index: 1;
	display: block;
	width: 100%;
	height: 45px;
	background-color: #ececec;
}

.home-image-link-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 25vw;
	max-height: 320px;
	min-height: 140px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 5px solid #163a59;
	background-color: #f3f5f7;
	background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-image-link-2:hover {
	opacity: 0.85;
}

.home-image-link-2.large-image-link {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 35%;
	-ms-flex-preferred-size: 35%;
	flex-basis: 35%;
	border-top-style: none;
	border-top-color: #ac342f;
}

.home-image-link-2.large-image-link.large-image-1 {
	border-top-style: none;
	border-top-color: #ac342f;
}

.newsletter-form-wrap-2 {
	display: block;
	width: auto;
	margin-top: 60px;
	margin-bottom: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.text-span-4 {
	color: #d11616;
}

.heading-3-wrap {
	position: absolute;
	top: 60px;
	right: 0px;
	display: block;
	width: auto;
	padding-right: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	text-align: right;
}

.home-cbox-learn-more-2 {
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 500;
	display: inline-block;
	margin-top: 0rem;
	padding-top: 0.38rem;
	padding-bottom: 0.25rem;
	padding-left: 0rem;
	border-bottom: 1px solid transparent;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 1.1rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 0.03rem;
	text-transform: uppercase;
}

.home-cbox-learn-more-2:hover {
	padding-left: 0rem;
	border-bottom: 1px solid #ac342f;
	color: #ac342f;
}

.home-cbox-learn-more-2.intro-cbox-learn-more {
	position: relative;
	padding: 1rem 2rem;
	border-bottom-style: none;
	background-color: #fff;
	font-size: 1em;
	font-weight: 500;
	letter-spacing: 0.07em;
	text-shadow: none;
}

.home-cbox-learn-more-2.intro-cbox-learn-more:hover {
	border-bottom-style: none;
	background-color: #394486;
	color: #fff;
}

.home-cbox-learn-more-2.intro-cbox-learn-more.button2 {
	display: block;
	width: 290px;
	height: 45px;
	padding: 0.5rem 1rem;
	border-style: solid;
	border-width: 1px;
	border-color: #263996 #394486 #394486;
	background-color: transparent;
	line-height: 1.7rem;
	text-align: center;
}

.home-cbox-learn-more-2.intro-cbox-learn-more.button2:hover {
	background-color: #263996;
}

.home-cbox-learn-more-2.intro-cbox-learn-more.button2.button3 {
	margin-bottom: 10px;
}

.home-cbox-learn-more-2.learn-tabmobile-row-1 {
	display: none;
}

.home-content-box-title-2 {
	margin-top: 0rem;
	margin-bottom: 30px;
	padding-top: 10px;
	font-family: Roboto, sans-serif;
	color: #394486;
	font-size: 1.625em;
	line-height: 1.125em;
	text-transform: uppercase;
}

.home-content-box-title-2.hero-cbox-title {
	margin-top: 0px;
	margin-bottom: 20px;
	padding-top: 0px;
	font-size: 1.75em;
}

.home-content-box-title-2.hero-cbox-title:hover {
	color: #394486;
}

.paragraph-8 {
	width: 350px;
	margin-top: -10px;
	margin-bottom: 2em;
	padding-top: 0px;
	line-height: 1.55em;
}

.home-content-box-wrap-2 {
	position: relative;
	border-top: 5px solid #ac342f;
	background-color: #fff;
}

.home-content-box-wrap-2.hero-cbox-wrap {
	background-color: transparent;
}

.home-content-box-wrap-2.hero-cbox-wrap.contact-box-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	border-top-style: solid;
	border-top-color: #f6c952;
}

.home-content-box-wrap-2.hero-cbox-wrap.carerrs-box {
	border-top-color: #f6c952;
}

.heading-4 {
	font-family: Roboto, sans-serif;
	color: #444;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
}

.h2-heading-2 {
	margin-top: 10px;
	padding-top: 10px;
	padding-bottom: 20px;
	padding-left: 0rem;
	border-top: 5px none #ac342f;
	background-color: hsla(0, 0%, 100%, 0.8);
	font-family: Roboto, sans-serif;
	color: #394486;
	font-size: 1.5rem;
	line-height: 1.125em;
	font-weight: 500;
	text-transform: uppercase;
}

.div-block-10 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0.75em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.link-4 {
	color: #3e3f3d;
}

.link-4:hover {
	color: #3e3f3d;
	text-decoration: none;
}

.column-contacts-left {
	margin-right: 20px;
}

.columns-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	padding-top: 0.5rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.paragraph-10 {
	padding-top: 0.125rem;
}

.heading-5 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-6 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-7 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-8 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-9 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-10 {
	font-family: Roboto, sans-serif;
}

.heading-11 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-12 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-13 {
	color: #263996;
}

.block-quote {
	border-left-color: #f6c952;
	background-color: rgba(38, 57, 150, 0.15);
}

.heading-14 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-15 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-16 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-17 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-18 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.heading-19 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000003;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

@media screen and (max-width: 991px) {
	h1 {
		font-size: 2.4em;
		line-height: 3.1rem;
	}

	.button.header-search-button {
		position: absolute;
		right: 0rem;
		width: 3rem;
		height: 2.875rem;
		opacity: 0.65;
		font-size: 1rem;
	}

	.button.header-search-button:hover {
		opacity: 1;
	}

	.button.slider-button {
		background-color: hsla(0, 0%, 100%, 0.65);
		font-family: Roboto, sans-serif;
		color: #263996;
		font-size: 0.9375em;
	}

	.button.slider-button:hover {
		padding-left: 3.5rem;
		background-color: hsla(0, 0%, 100%, 0.9);
		color: #ee1b2c;
	}

	.container {
		padding-right: 20px;
		padding-left: 20px;
	}

	.container.footer-container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.nav-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.nav-link {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1rem 1rem 0.875rem;
		border-left: 5px solid transparent;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		font-weight: 500;
		text-align: left;
		text-transform: none;
	}

	.nav-link:hover {
		border-left: 5px solid #f6c952;
		background-color: rgba(0, 0, 0, 0.15);
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.nav-link.w--current {
		background-color: rgba(0, 0, 0, 0.15);
		box-shadow: inset 5px 0 0 0 #76a009, 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
	}

	.nav-link.w--current:hover {
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: inset 5px 0 0 0 #163a59, 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
	}

	.nav-link.mobile-list-back {
		position: relative;
		padding-left: 2.25rem;
		background-color: rgba(0, 0, 0, 0.25);
		color: hsla(0, 0%, 100%, 0.55);
		cursor: pointer;
	}

	.nav-link.current {
		border-left: 5px solid #f6c952;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.nav-link.current:hover {
		border-left-color: transparent;
	}

	.nav-link.not-current.mobile-link {
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.nav-link.not-current.mobile-link:hover {
		box-shadow: 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	}

	.nav-menu-wrap {
		display: block;
		overflow: hidden;
		margin-top: 170px;
		border-left-style: none;
		background-color: #263996;
		text-align: left;
	}

	.logo {
		margin-left: 0.75em;
		font-size: 1.25rem;
	}

	.logo.w--current {
		left: auto;
		margin-left: 0px;
	}

	.logo.logo-footer {
		left: auto;
		margin-left: 0em;
	}

	.row.footer-signoff-row {
		margin-right: 0px;
		padding-left: 10px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.footer-signoff-row._1._11 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-left: 40px;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		-webkit-align-content: space-between;
		-ms-flex-line-pack: justify;
		align-content: space-between;
	}

	.row.footer-signoff-row._11 {
		display: block;
	}

	.row.home-cbox-row-3 {
		margin-bottom: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-align-content: space-between;
		-ms-flex-line-pack: justify;
		align-content: space-between;
	}

	.row.home-cbox-row-1 {
		margin-bottom: 0rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-column {
		margin-bottom: 1.5em;
		padding-top: 0rem;
		padding-right: 2rem;
		padding-left: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.column.footer-signoff-column.right-signoff-column {
		margin-bottom: 0em;
		padding-right: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.column.column-2 {
		padding-left: 10px;
	}

	.column.column-2 {
		padding-left: 10px;
	}

	.menu-button {
		height: 3.5rem;
		padding: 0px 1.25rem 0px 0.75rem;
		float: left;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		background-color: transparent;
		-webkit-transition: all 250ms ease;
		transition: all 250ms ease;
		font-family: Khand;
		color: #fff;
		line-height: 3.5rem;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.menu-button.w--open {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.menu-button.w--open:hover {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.footer-signoff-section {
		text-align: center;
	}

	.copyright-text {
		margin-right: 0rem;
		text-align: left;
	}

	.footer-signoff-list {
		margin-right: 0rem;
		margin-left: 0rem;
		text-align: left;
	}

	.footer-signoff-grip {
		margin-right: 0.75rem;
		float: none;
	}

	.footer-column-wrap {
		width: 100%;
		padding-right: 40px;
		padding-left: 40px;
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3;
	}

	.footer-column-wrap.footer-social-column {
		max-width: none;
		padding-right: 0rem;
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2;
		border-top-style: none;
		text-align: center;
	}

	.footer-column-wrap.footer-updates-column {
		max-width: 100%;
		margin-bottom: -1.5rem;
		padding-bottom: 3rem;
		padding-left: 1.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		border-style: none;
		border-right-width: 1px;
		border-right-color: rgba(0, 0, 0, 0.1);
		border-bottom-width: 1px;
		border-bottom-color: rgba(0, 0, 0, 0.15);
	}

	.footer-column-title {
		font-size: 1.125rem;
	}

	.footer-column-title.footer-notifications-title {
		font-size: 1.125rem;
	}

	.footer-column-title.footer-title {
		padding-right: 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.footer-column-title.footer-title._1 {
		margin-top: 0em;
	}

	.header-content-right-wrap {
		margin-right: 0.75em;
		padding-bottom: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.form-field.right-sidebar-field {
		margin-bottom: 1.5em;
	}

	.form-field.right-sidebar-field.miles-from {
		width: 100%;
		margin-bottom: 0.25em;
	}

	.form-field.right-sidebar-field.zip-code {
		width: 100%;
	}

	.form-field.header-search-field {
		width: 70%;
		height: 2.875rem;
		padding-left: 1em;
		-webkit-box-flex: 100%;
		-webkit-flex: 100%;
		-ms-flex: 100%;
		flex: 100%;
		font-size: 0.8125rem;
	}

	.form-field.header-search-field:focus {
		width: 280px;
		min-width: 280px;
	}

	.form-field.newsletter-form-field {
		width: 300px;
	}

	.form-field.newsletter-form-field {
		width: 340px;
		margin-bottom: 30px;
	}

	.secondary-nav-list {
		display: block;
		margin-top: 0rem;
		margin-bottom: 0em;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.secondary-nav-list-item {
		display: none;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		-ms-grid-row-align: stretch;
		align-self: stretch;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-webkit-flex-basis: auto;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
		border-left-width: 1px;
		border-left-color: rgba(0, 0, 0, 0.25);
	}

	.right-sidebar-column {
		margin-top: 3rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
		padding-right: 0px;
		padding-left: 0px;
	}

	.secondary-nav-icon {
		width: 1.375rem;
		height: 1.375rem;
		margin-top: -0.1875em;
		margin-right: 0.5rem;
		padding: 2px;
		background-color: transparent;
	}

	.secondary-nav-icon.idea-icon {
		width: 2.25em;
		height: 2.25em;
		margin-top: 0em;
	}

	.secondary-nav-list-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0.22rem;
		padding-right: 1.25rem;
		padding-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-family: 'Roboto Condensed', sans-serif;
		color: #fff;
		font-size: 1.125rem;
		font-weight: 400;
	}

	.secondary-nav-list-link:hover {
		background-color: rgba(0, 0, 0, 0.25);
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		color: #fff;
	}

	.secondary-nav-list-link:active {
		background-color: transparent;
	}

	.secondary-nav-list-link:focus {
		color: #fff;
	}

	.slider-section {
		top: 168px;
	}

	.slider {
		height: auto;
		max-height: none;
		min-height: 400px;
	}

	.slider-arrow {
		top: 400px;
		bottom: auto;
		width: 4rem;
		height: 3rem;
		margin-top: -3rem;
		background-color: transparent;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		color: #e4e7eb;
		font-size: 1.375rem;
	}

	.slider-arrow:hover {
		background-color: transparent;
	}

	.slider-arrow:active {
		background-color: transparent;
	}

	.slider-indicator {
		top: 400px;
		bottom: auto;
		margin-top: -1em;
	}

	.home-section {
		margin-top: 160px;
	}

	.home-image-link {
		height: 25vw;
		max-height: 240px;
	}

	.home-image-link.large-image-link {
		min-height: 220px;
		min-width: 45%;
	}

	.home-content-box-wrap {
		margin-bottom: 1.5rem;
	}

	.home-content-box-wrap.intro-content-box-wrap {
		margin-bottom: 0rem;
		padding-bottom: 0rem;
	}

	.home-content-box-wrap.hero-cbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.home-content-box-title.intro-cbox-title {
		font-size: 2.25em;
	}

	.home-cbox-learn-more.intro-cbox-learn-more.button2 {
		width: 300px;
	}

	.home-cbox-learn-more.learn-tabmobile-row-1 {
		display: block;
	}

	.slider-image {
		position: static;
		height: 400px;
	}

	.slider-caption-outer-wrap {
		position: static;
		top: 107px;
		display: block;
		width: 100%;
		max-width: none;
		margin-right: auto;
		margin-bottom: 0px;
		margin-left: auto;
		padding: 0rem 20px 2rem;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		color: #3e3f3d;
	}

	.inside-page-header-section {
		display: none;
	}

	.inside-content-beckground {
		margin-top: 170px;
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.main-content-wrap.nested-content-wrap {
		margin-right: 0em;
	}

	.menu-button-icon {
		float: left;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		font-size: 2rem;
		line-height: 3.5rem;
	}

	.menu-button-text {
		margin-left: 0.125rem;
		padding-top: 0.125em;
		float: left;
		font-family: 'Roboto Condensed', sans-serif;
		color: #fff;
		font-size: 1.125rem;
		line-height: 3.4125rem;
		letter-spacing: 0.02em;
	}

	.header-menu-inside-wrap {
		max-width: 100%;
	}

	.home-cbox-text-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		font-size: 0.9375rem;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		margin-left: 3%;
		padding-top: 0rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		font-size: 1.0625rem;
	}

	.home-cbox-text-wrap.intro-cbox-text-wrap {
		max-width: 90%;
		font-size: 1.25rem;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		margin-left: 3%;
		padding-top: 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		font-size: 1.0625rem;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap.hero-box1 {
		margin-left: 0%;
		padding-top: 2rem;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap.contact-box {
		margin-left: 0%;
		padding-bottom: 0rem;
	}

	.slider-caption-title {
		display: block;
		margin-bottom: 0em;
		padding-top: 0em;
		padding-left: 0rem;
		background-color: transparent;
		box-shadow: none;
		font-size: 2.5em;
		line-height: 3rem;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.link.breadcrumb-link {
		color: #797676;
	}

	.link.breadcrumb-link:hover {
		color: #3e3f3d;
	}

	.loc-miles-from-zip-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.loc-type-form-checkbox {
		width: 33.33%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 33.33%;
		margin-bottom: 0.75em;
	}

	.loc-type-form-checkbox.sidebar-checkbox.select-all {
		width: 100%;
		margin-top: 0.75em;
	}

	.loc-type-form-checkboxes-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.loc-sidebar-search-reveal-button {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.loc-refine-search-checkbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0.75em;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.1);
		background-image: url('/20181016082423/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 16px 16px;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		color: transparent;
		font-size: 1.125rem;
		line-height: 1rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle:hover {
		background-color: rgba(0, 0, 0, 0.15);
	}

	.two-part-button-list {
		position: absolute;
		left: auto;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #263996;
	}

	.two-part-drop-list-back-arrow {
		position: absolute;
		left: 1rem;
		top: 50%;
		display: inline-block;
		min-width: 0.75em;
		margin-right: 0.25em;
		background-image: url('/20181016082423/assets/images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		-webkit-transform: translate(0px, -50%);
		-ms-transform: translate(0px, -50%);
		transform: translate(0px, -50%);
		color: transparent;
		line-height: 1rem;
		text-align: center;
	}

	.header-container {
		padding-left: 1rem;
	}

	.secondary-header-item-wrap {
		margin-right: 0rem;
		margin-left: 0rem;
		font-size: 1.125rem;
	}

	.header-content-right-row {
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.secondary-header-item-cta-link {
		margin-left: 1.5rem;
		padding-top: 0.8125em;
		padding-bottom: 0.625em;
	}

	.header-search-wrap {
		margin-left: 0rem;
		padding: 0rem 0rem 0px;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.header-search-form {
		position: relative;
		padding: 0rem;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
	}

	.slider-caption-inner-wrap {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.slider-caption-heading {
		background-color: transparent;
		box-shadow: none;
	}

	.home-content-box-section-wrap {
		padding-top: 2.5em;
		padding-bottom: 1.5em;
	}

	.slider-button-arrow-span {
		background-color: hsla(0, 0%, 100%, 0.25);
		background-image: url('/20181016082423/assets/images/icon_arrow2_right_blk_25.svg');
		background-size: 12px 12px;
	}

	.intro-cbox-section {
		margin-top: 1em;
		padding-top: 2em;
		padding-bottom: 2em;
	}

	.footer-flex-row {
		width: auto;
		margin-right: auto;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		width: 100%;
	}

	.footer-signoff-acrobat-link {
		margin-right: 1em;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.acrobat-reader-image {
		width: 125px;
	}

	.slider-image-overlay {
		display: none;
	}

	.footer-contact-wrap {
		padding-right: 1rem;
	}

	.footer-contact-wrap.quick-links {
		width: 100%;
		padding-top: 10px;
		padding-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.footer-contact-wrap.contact-wrap {
		width: 50%;
	}

	.footer-social-block {
		padding-right: 1rem;
		padding-left: 0px;
		text-align: left;
	}

	.footer-social-block.footer-block {
		margin-right: 10px;
		padding-right: 0rem;
		padding-bottom: 0rem;
	}

	.footer-social-block.footer-block._1 {
		display: none;
	}

	.footer-notifications-image {
		width: 8vw;
		height: 8vw;
		max-height: 5rem;
		max-width: 5rem;
		margin-right: 4vw;
		margin-left: 4vw;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.home-content-box {
		max-width: 50%;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.home-content-box.newsletter-column {
		max-width: 100%;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.home-content-box.newsletter-column {
		max-width: 100%;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.paragraph-4 {
		width: 300px;
	}

	.column-3 {
		padding-left: 0px;
	}

	.div-block-5 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 50px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.image-3 {
		position: absolute;
		display: block;
	}

	.heading-3 {
		width: 300px;
		font-size: 1.1rem;
		line-height: 1.5rem;
		text-align: left;
	}

	.paragraph-5 {
		width: 300px;
		margin-bottom: 1.25rem;
	}

	.div-block-7 {
		left: 25%;
	}

	.home-image-link-2 {
		height: 25vw;
		max-height: 240px;
	}

	.home-image-link-2.large-image-link {
		min-height: 220px;
		min-width: 45%;
	}

	.heading-3-wrap {
		top: 50px;
	}

	.home-cbox-learn-more-2 {
		padding-top: 0rem;
	}

	.home-cbox-learn-more-2.intro-cbox-learn-more.button2 {
		width: 175px;
	}

	.home-cbox-learn-more-2.learn-tabmobile-row-1 {
		display: block;
	}

	.paragraph-8 {
		width: 300px;
		margin-bottom: 2rem;
	}

	.home-content-box-wrap-2 {
		margin-bottom: 1.5rem;
	}

	.home-content-box-wrap-2.hero-cbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.home-content-box-wrap-2.hero-cbox-wrap.carerrs-box {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.quick-links-wrap {
		width: 50%;
	}

	.connect-wrap {
		width: 50%;
	}
}

@media screen and (max-width: 767px) {
	body {
		font-size: 0.875rem;
		line-height: 1.375rem;
	}

	h1 {
		font-size: 2.25em;
		line-height: 2.55rem;
	}

	h2 {
		font-size: 1.8rem;
		line-height: 2.3rem;
	}

	h3 {
		font-size: 1.5rem;
		line-height: 1.9rem;
	}

	h4 {
		font-size: 1.3rem;
		line-height: 1.8rem;
	}

	h5 {
		font-size: 1.15rem;
		line-height: 1.6rem;
	}

	p {
		font-size: 1.05rem;
		line-height: 1.55rem;
	}

	ul {
		font-size: 1.05rem;
		line-height: 1.55rem;
	}

	blockquote {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	.button.secondary.wishlist {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.button.header-search-button {
		width: 15%;
		height: 3.25rem;
		font-size: 1.125rem;
	}

	.button.slider-button {
		background-color: hsla(0, 0%, 100%, 0.65);
		color: #263996;
		font-size: 1.0625em;
	}

	.button.slider-button:hover {
		padding-left: 3.5rem;
		background-color: hsla(0, 0%, 100%, 0.9);
		color: #ee1b2c;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.footer-signoff-section .container {
		padding-right: 40px;
		padding-left: 40px;
	}

	.nav-link {
		font-size: 1.125rem;
		letter-spacing: 0.04em;
	}

	.nav-link.not-current.mobile-link:hover {
		box-shadow: 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	}

	.nav-menu-wrap {
		margin-top: 165px;
	}

	.logo {
		margin-top: 1em;
		margin-bottom: 1em;
		margin-left: 0.5em;
		padding-left: 0px;
	}

	.logo.w--current {
		margin-top: 0.125em;
		margin-bottom: 0.125em;
		margin-left: 0px;
	}

	.footer-section {
		background-color: #ececec;
		background-image: none;
	}

	.row.footer-signoff-row._1._11 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 40px;
		padding-left: 40px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.row.footer-signoff-row._11 {
		padding-top: 0rem;
	}

	.column.shop-detail-image-col {
		padding-right: 0px;
	}

	.column.footer-signoff-column {
		margin-top: 1.5rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: left;
	}

	.column.footer-signoff-column.right-signoff-column {
		margin-top: 0.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.logo-image {
		width: 200px;
	}

	.copyright-text {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.footer-signoff-grip {
		text-align: right;
	}

	.footer-column-wrap {
		width: 100%;
		margin-top: 0.5rem;
		margin-right: 0rem;
		margin-bottom: 0rem;
		padding-top: 0.5rem;
		padding-right: 20px;
		padding-left: 20px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.footer-column-wrap.footer-social-column {
		padding-top: 0rem;
		padding-bottom: 0.5rem;
	}

	.footer-column-wrap.footer-updates-column {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		border-right-style: none;
	}

	.footer-column-title.footer-title {
		margin-left: 0px;
		padding-left: 0px;
	}

	.header-content-right-wrap {
		height: 60px;
		margin-right: 1em;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.form-field.header-search-field {
		height: 3.25rem;
		font-size: 1em;
	}

	.form-field.newsletter-form-field {
		width: 528px;
	}

	.form-success {
		font-size: 1rem;
	}

	.form-error {
		font-size: 1rem;
	}

	.secondary-nav-list {
		max-width: 50%;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.secondary-nav-icon.idea-icon {
		width: 1.5em;
		height: 1.5em;
		margin-right: 0.375rem;
		margin-bottom: 0rem;
		padding: 3px 3px 5px;
	}

	.secondary-nav-list-link {
		padding-top: 2px;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.slider-section {
		top: 168px;
		margin-top: 0px;
		background-color: #f8f9fa;
	}

	.slider {
		height: auto;
		max-height: none;
		min-height: 300px;
	}

	.slider-arrow {
		top: 300px;
		bottom: auto;
		width: 3.25rem;
		margin-top: -3rem;
	}

	.slider-indicator {
		top: 300px;
		bottom: auto;
		margin-bottom: 0em;
	}

	.home-section {
		margin-top: 160px;
	}

	.home-image-link {
		height: 50vw;
		max-height: 300px;
	}

	.home-image-link.large-image-link {
		width: 100%;
		max-height: 260px;
	}

	.home-image-link.home-cbox-image-1 {
		border-top-color: #ac342f;
	}

	.home-image-link.home-cbox-image-2 {
		border-top-color: #ac342f;
	}

	.home-content-box-wrap {
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	}

	.home-content-box-wrap.intro-content-box-wrap {
		width: auto;
		margin-right: 10px;
		margin-left: 10px;
		float: none;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom-style: none;
	}

	.home-content-box-wrap.hero-cbox-wrap {
		margin-bottom: 1.5rem;
		padding-bottom: 3rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom-color: rgba(0, 0, 0, 0.25);
	}

	.home-content-box-wrap.hero-cbox-wrap.last-hero-cbox {
		margin-bottom: 0rem;
		border-bottom-style: none;
	}

	.home-content-box-wrap.last-cbox-wrap {
		border-bottom-style: none;
	}

	.home-content-box-title {
		line-height: 1.125em;
	}

	.home-cbox-learn-more {
		margin-bottom: 1rem;
	}

	.slider-image {
		position: relative;
		height: 300px;
	}

	.slider-caption-outer-wrap {
		top: 100%;
		max-width: 100%;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.shop-item-wrap.flex {
		width: 48%;
	}

	.inside-page-header-content-wrap {
		margin-right: 10px;
		margin-left: 10px;
	}

	.inside-page-header-section {
		height: 60px;
	}

	.inside-content-beckground {
		margin-top: 165px;
		padding-top: 1rem;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.home-cbox-text-wrap {
		padding-bottom: 2.75rem;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		width: 100%;
		margin-left: 0%;
		padding-top: 1.125em;
		float: left;
	}

	.home-cbox-text-wrap.intro-cbox-text-wrap {
		max-width: none;
		font-size: 1.125rem;
		text-align: left;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		width: 100%;
		margin-left: 0%;
		padding-top: 1.125em;
		float: left;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap.contact-box {
		width: 100%;
	}

	.slider-caption-title {
		background-color: transparent;
		box-shadow: none;
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	.footer-social-icons-wrap {
		margin-bottom: 0em;
		padding-right: 10px;
	}

	.footer-social-icon-link {
		margin-right: 10px;
	}

	.footer-social-icon-image {
		width: 36px;
		height: 36px;
	}

	.home-cbox-dynamic-feed-item-wrap {
		width: 100%;
		margin-right: 0%;
	}

	.footer-link {
		font-size: 1.125rem;
	}

	.link.content-box-title-link:hover {
		border-bottom-color: transparent;
	}

	.link.content-box-title-link:hover {
		border-bottom-color: transparent;
	}

	.pagination-middle-wrap {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.pagination-wrap.flex-pagination {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.loc-type-form-checkbox {
		width: 50%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 50%;
	}

	.pagination-right-side-wrap {
		display: none;
	}

	.header-container {
		padding-bottom: 0px;
		padding-left: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.secondary-header-item-wrap {
		font-size: 1.125rem;
	}

	.secondary-header-item-cta-link {
		margin-left: 0rem;
	}

	.header-search-wrap {
		display: none;
		padding-bottom: 1px;
	}

	.shop-detail-attributes-wrap {
		margin-top: 1.5em;
	}

	.slider-caption-inner-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.slider-caption-heading {
		margin-top: 0em;
		background-color: transparent;
		box-shadow: none;
		color: #3e3f3d;
		font-size: 1.3rem;
		line-height: 1.8rem;
		text-shadow: none;
	}

	.slider-button-arrow-span {
		border-right-color: rgba(0, 0, 0, 0.1);
		background-color: transparent;
		background-image: url('/20181016082423/assets/images/icon_arrow2_right_blk_25.svg');
		background-size: 7px;
	}

	.intro-cbox-section {
		margin-top: 0em;
	}

	.footer-flex-row {
		margin-left: 0px;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-contact-wrap {
		padding-right: 1rem;
	}

	.footer-contact-wrap.logo-footer {
		width: 40%;
	}

	.footer-contact-wrap.quick-links {
		padding-left: 0px;
	}

	.footer-contact-wrap.contact-wrap {
		width: 60%;
	}

	.footer-social-block {
		padding-left: 0rem;
	}

	.footer-social-block.footer-block {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		text-align: left;
	}

	.footer-social-block.footer-block.right-block {
		width: 50%;
		padding-left: 10px;
	}

	.footer-notifications-image {
		width: 10vw;
		height: 10vw;
		margin-right: 5%;
		margin-left: 5%;
	}

	.content-image-example {
		width: 100%;
		margin-bottom: 15px;
		margin-left: 0px;
		float: none;
	}

	.link-block {
		display: none;
	}

	.heading {
		width: 100%;
		font-size: 1.5rem;
	}

	.text-block {
		width: 100%;
		font-size: 1.8rem;
	}

	.div-block-2 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 200px;
		height: 100px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-grip-link.color {
		margin-left: -120px;
	}

	.div-block-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		text-align: right;
	}

	.newsletter-form-wrap {
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.home-content-box {
		padding-right: 0rem;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.home-content-box.newsletter-column {
		max-width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.home-content-box.newsletter-column {
		max-width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.column-3 {
		margin-top: -50px;
		padding-right: 20px;
		padding-left: 30px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.div-block-5 {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.image-3 {
		position: relative;
	}

	.div-block-7 {
		left: 10%;
	}

	.home-image-link-2 {
		height: 50vw;
		max-height: 300px;
	}

	.home-image-link-2.large-image-link {
		width: 100%;
		max-height: 260px;
	}

	.newsletter-form-wrap-2 {
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.home-cbox-learn-more-2 {
		margin-bottom: 1rem;
	}

	.home-cbox-learn-more-2.intro-cbox-learn-more.button2 {
		width: 275px;
	}

	.home-content-box-title-2 {
		line-height: 1.125em;
	}

	.home-content-box-wrap-2 {
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	}

	.home-content-box-wrap-2.hero-cbox-wrap {
		margin-bottom: 1.5rem;
		padding-bottom: 3rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom-color: rgba(0, 0, 0, 0.25);
	}

	.home-content-box-wrap-2.hero-cbox-wrap.contact-box-wrap {
		width: 100%;
		margin-bottom: 0rem;
		padding-bottom: 0rem;
	}

	.paragraph-9 {
		width: auto;
	}

	.quick-links-wrap {
		width: 40%;
	}

	.connect-wrap {
		width: 60%;
	}

	.paragraph-11 {
		padding-top: 1.5rem;
	}
}

@media screen and (max-width: 479px) {
	h1 {
		font-size: 2em;
		line-height: 2.3rem;
	}

	h2 {
		font-size: 1.6rem;
		line-height: 2rem;
	}

	blockquote {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.button.secondary.wishlist {
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.button.header-search-button {
		width: 20%;
		height: 3rem;
	}

	.button.add-to-cart {
		max-width: 100%;
		margin-right: 0em;
	}

	.button.slider-button {
		font-size: 1em;
	}

	.nav-link {
		padding-left: 1rem;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.nav-link:hover {
		box-shadow: none;
	}

	.nav-menu-wrap {
		margin-top: 180px;
	}

	.logo {
		right: 0.375em;
		margin: 1.25em auto;
		font-size: 1rem;
	}

	.logo.w--current {
		margin-left: auto;
	}

	.logo.logo-header {
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.row.footer-signoff-row {
		padding-right: 10px;
		padding-left: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.row.footer-signoff-row._1._11 {
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.column.footer-signoff-column {
		padding-right: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-column.right-signoff-column {
		margin-top: 0rem;
		margin-bottom: 0em;
		padding-left: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.logo-image {
		width: 180px;
	}

	.logo-image.footer-logo {
		width: 120px !important;
	}

	.menu-button {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.footer-signoff-section {
		padding-bottom: 0rem;
	}

	.copyright-text {
		margin-left: 0.5rem;
		text-align: center;
	}

	.footer-signoff-list {
		margin-top: 0.75rem;
		text-align: center;
	}

	.footer-signoff-list-item {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.footer-signoff-grip {
		margin-right: 1rem;
		margin-bottom: 1.5rem;
		margin-left: 1rem;
	}

	.footer-column-wrap {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.footer-column-wrap.footer-updates-column {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap-reverse;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
		text-align: center;
	}

	.footer-column-title {
		width: 200px;
	}

	.header-content-right-wrap {
		display: none;
		margin-right: 0em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.form.newsletter-form {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.form.newsletter-form {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.form-field.header-search-field {
		height: 3rem;
	}

	.form-field.newsletter-form-field {
		width: 230px;
		text-align: center;
	}

	.form-field.newsletter-form-field {
		width: 280px;
		text-align: center;
	}

	.secondary-nav-list {
		max-width: 136px;
	}

	.secondary-nav-list-item {
		max-width: none;
		margin-right: 0.25rem;
	}

	.secondary-nav-icon {
		margin-top: 0em;
		margin-right: 0rem;
	}

	.secondary-nav-icon.contact-us-nav-icon {
		width: 1.5rem;
		height: 1.5rem;
	}

	.secondary-nav-text {
		display: none;
	}

	.secondary-nav-list-link {
		padding-top: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.slider-section {
		top: 190px;
	}

	.slider-arrow {
		top: 230px;
	}

	.slider-indicator {
		top: 230px;
	}

	.home-section {
		margin-top: 180px;
	}

	.home-image-link {
		height: 45vw;
		min-height: 120px;
	}

	.home-image-link.large-image-link {
		min-height: 180px;
	}

	.home-content-box-title.intro-cbox-title {
		font-size: 1.5625em;
	}

	.home-cbox-learn-more.intro-cbox-learn-more.button2 {
		width: 230px;
	}

	.home-cbox-learn-more.intro-cbox-learn-more.button2.button3 {
		width: 230px;
	}

	.slider-image {
		height: 230px;
	}

	.shop-item-wrap.flex {
		width: 100%;
		margin-right: 0%;
		margin-left: 0%;
	}

	.inside-content-beckground {
		margin-top: 188px;
	}

	.menu-button-text {
		display: none;
	}

	.home-cbox-text-wrap {
		width: 100%;
		float: none;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		font-size: 0.9375rem;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		font-size: 0.9375rem;
	}

	.slider-caption-title {
		font-size: 2em;
		line-height: 2.3rem;
	}

	.footer-social-icons-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-social-icon-link {
		margin-right: 12px;
		margin-bottom: 0px;
		margin-left: 12px;
	}

	.footer-newsletter-wrap {
		width: 100%;
		padding-left: 0px;
		float: none;
	}

	.footer-link {
		width: 200px;
	}

	.pagination-middle-wrap {
		min-width: 100%;
		margin-top: 0.75em;
		padding-top: 0.75em;
		border-top: 1px solid rgba(84, 159, 196, 0.25);
	}

	.pagination-wrap.flex-pagination {
		padding-right: 0%;
		padding-left: 0%;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.loc-type-form-checkbox {
		width: 100%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 100%;
	}

	.pagination-left-side-wrap {
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.header-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.shop-detail-form-buttons-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.slider-caption-heading {
		font-size: 1.2rem;
		line-height: 1.7rem;
	}

	.footer-flex-row {
		margin-right: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-signoff-acrobat-link {
		margin-right: 0rem;
		margin-bottom: 0.5rem;
		margin-left: 1rem;
	}

	.footer-contact-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-contact-wrap.logo-footer {
		width: 50%;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.footer-contact-wrap.quick-links {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-contact-wrap.contact-wrap {
		width: 100%;
		padding-left: 0rem;
	}

	.footer-social-block {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-bottom: 1rem;
		padding-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.footer-social-block.footer-block {
		width: 20%;
		margin-right: 0px;
		padding-right: 1rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.footer-social-block.footer-block.right-block {
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.footer-notifications-image {
		width: 15vw;
		height: 15vw;
		margin-bottom: 2vw;
	}

	.heading {
		text-align: center;
	}

	.footer-grip-link.color {
		margin-left: 0px;
	}

	.div-block-3 {
		width: 100%;
		margin-bottom: 0.125rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.newsletter-text-wrap {
		margin-right: 0vw;
	}

	.newsletter-form-field-wrap {
		width: 100%;
		margin-right: 0px;
	}

	.newsletter-form-wrap {
		margin-bottom: 1rem;
	}

	.home-content-box {
		max-width: 100%;
		padding-right: 0rem;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.home-content-box.newsletter-column {
		height: 440px;
	}

	.home-content-box.newsletter-column {
		height: 440px;
	}

	.button-2.newsletter-form-button {
		margin-top: 0.75em;
	}

	.paragraph-4 {
		width: 230px;
		margin-bottom: 1em;
		line-height: 1.5rem;
	}

	.column-3 {
		margin-top: -40px;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.div-block-5 {
		width: 100%;
		margin-right: 0;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-start;
	}

	.div-block-5._3 {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.text-block-2 {
		padding-left: 0px;
	}

	.heading-3 {
		width: 230px;
	}

	.paragraph-5 {
		width: 230px;
	}

	.paragraph-7 {
		width: 200px;
	}

	.div-block-7 {
		left: 0%;
		width: auto;
	}

	.div-block-8 {
		display: none;
	}

	.home-image-link-2 {
		height: 45vw;
		min-height: 120px;
	}

	.home-image-link-2.large-image-link {
		min-height: 180px;
	}

	.newsletter-form-wrap-2 {
		margin-bottom: 1rem;
	}

	.heading-3-wrap {
		top: 40px;
	}

	.home-cbox-learn-more-2.intro-cbox-learn-more.button2 {
		width: 140px;
	}

	.home-cbox-learn-more-2.intro-cbox-learn-more.button2.button3 {
		width: 230px;
	}

	.paragraph-8 {
		width: 230px;
		margin-bottom: 1em;
	}

	.columns-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.quick-links-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.connect-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.paragraph-11 {
		padding-top: 0rem;
	}

	.paragraph-12 {
		margin-bottom: 0em;
	}
}

/****************** Webflow ***********************/
/****************************************************/
html {
	font-size: 16px;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
@media (max-width: 991px) {
	.header-menu-wrap {
		height: 56px;
	}

	.menu-button {
		display: block;
	}
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu {
	display: flex;
	width: 100%;
}

.accesible-navigation-menu ul {
	margin: 0px;
	padding: 0px;
}

.accesible-navigation-menu li {
	margin-bottom: 0px;
}

.accesible-navigation-menu li:hover .nav-drop-toggle-link,
.accesible-navigation-menu li .nav-drop-toggle-link.active,
.accesible-navigation-menu li .nav-drop-toggle-link:hover {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: 0 -5px 0 0 #f6c952;
	color: #fff;
}

.accesible-navigation-menu li.nav-drop-wrap .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: auto;
	transition: opacity 200ms ease 0s;
	height: 0;
	margin-top: -2px;
	width: 100%;
}

.accesible-navigation-menu li.nav-drop-wrap .container-wrapper ul {
	display: none;
	background-color: #f6c952;
	margin: 0px;
	list-style-type: none;
	min-width: 200px;
	padding: 0.25rem 0em;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.25)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
	width: 100%;
}

.accesible-navigation-menu li.nav-drop-wrap .container-wrapper ul a {
	margin-bottom: 0.125rem;
	padding: 0.75rem 2rem 0.75rem 0.75rem;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.875rem;
	line-height: 1.1rem;
	font-weight: 400;
	display: inline-block;
	width: 100%;
	border-bottom: 0px;
	text-decoration: none;
	background-color: #263996;
}

.accesible-navigation-menu li.nav-drop-wrap .container-wrapper ul a:hover,
.accesible-navigation-menu li.nav-drop-wrap .container-wrapper ul a.w--current {
	background-color: #263996;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	color: #fff;
}

.accesible-navigation-menu li.nav-drop-wrap.open .container-wrapper,
.accesible-navigation-menu li.nav-drop-wrap:hover .container-wrapper {
	opacity: 1;
	max-height: 70vh;
	height: auto;
	overflow-y: auto;
}

.accesible-navigation-menu li.nav-drop-wrap.open .container-wrapper ul,
.accesible-navigation-menu li.nav-drop-wrap:hover .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mobile-menu-button {
		display: none;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
		width: 80vw;
	}
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #263996;
}

.mobile-navigation-menu li a.nav-a,
.mm-navbar__title > span {
	text-align: left;
	font-family: Roboto, sans-serif;
	color: #fff;
	padding: 1rem 1rem 0.875rem;
	border-left: 5px solid transparent;
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 1.375rem;
}

.mm-navbar__title > span {
	border-left: 0px;
	padding-left: 0px;
}

.mobile-navigation-menu li.mm-listitem.active a.nav-a,
.mobile-navigation-menu li.mm-listitem:hover a.nav-a {
	border-left-color: #f6c952;
	background-color: rgba(0, 0, 0, 0.15);
}

.mm-listitem_selected > .mm-listitem__text {
	background-color: transparent;
}

.mm-navbar > * {
	justify-content: flex-start;
}

.mm-listitem__btn {
	border-color: hsl(0deg 0% 100% / 25%);
	background-color: rgba(0, 0, 0, 0.1);
}

.mm-listitem:after {
	border-color: hsl(0deg 0% 100% / 25%);
	left: 0;
}

.mm-navbar {
	border-color: hsl(0deg 0% 100% / 25%);
}

.mm-btn_next:after,
.mm-btn_prev:before {
	border-color: hsl(0deg 0% 100% / 60%);
	width: 10px;
	height: 10px;
}

/********************* Homepage *********************/
/*****************************************************/
.home-content-box-title a,
.home-content-box-title a:hover,
.content-box-title-link a,
.content-box-title-link a:hover {
	color: inherit !important;
	line-height: inherit;
	font-size: inherit !important;
	font-weight: 500 !important;
	font-family: inherit;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.cboxes.paragraph {
	margin-bottom: 0.75em;
}

.cboxes {
	width: 100%;
}

.cboxes p,
.cboxes li,
.cboxes a,
.cboxes a:hover,
.cboxes td,
.cboxes span {
	color: inherit;
}

.cboxes p {
	color: inherit;
	font-weight: inherit;
	font-family: inherit;
	text-decoration: none;
}

.cboxes.white p,
.cboxes.white li,
.cboxes.white a,
.cboxes.white a:hover,
.cboxes.white td,
.cboxes.white span,
.cboxes.white h1,
.cboxes.white h2,
.cboxes.white h3,
.cboxes.white h4,
.cboxes.white h5,
.cboxes.white h6 {
	color: #fff !important;
}

.cboxes.paragraph.white p {
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.55rem;
}

@media screen and (max-width: 991px) {
	.home-content-box-wrap.hero-cbox-wrap.carerrs-box {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

@media (max-width: 767px) {
	#testimonials_module_contentbox,
	#testimonials_module_contentbox .testimonials-content {
		text-align: left;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-caption-heading p {
	color: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	font-family: inherit;
}

.slider-caption-heading p:last-child {
	margin-bottom: 0px;
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube.com"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumb-list li:last-child div.breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child .link.breadcrumb-link:hover,
.breadcrumb-list li:last-child .link.breadcrumb-link {
	color: #444;
}

.left-nav-list-link.active {
	border-left-color: #f6c952;
	background-color: transparent;
	color: #263996;
	font-weight: 400;
}

.grandchild-left-nav-link.active,
.great-gchild-left-nav-link.active {
	border-left-color: #f6c952;
	background-color: rgba(57, 68, 134, 0.15);
	color: #263996;
}

.left-nav-nested-list li.has-children {
	box-shadow: none;
}

.inside-row {
	min-height: 500px;
}

.only-right .right-sidebar-column {
	padding-left: 10px;
	padding-right: 10px;
}

.only-right .main-content-column {
	padding-left: 0px;
	padding-right: 0px;
}

.only-right .main-content-column-nested {
	padding-left: 10px;
	padding-right: 10px;
}

@media (max-width: 767px) {
	.only-right .container,
	.only-left .container,
	.both-columns .container,
	.no-columns .container {
		padding-right: 10px;
		padding-left: 10px;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-sidebar-outer-wrap td img {
	max-width: none;
}

.right-sidebar-outer-wrap .box {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	padding-top: 0.75em;
	padding-right: 3px;
	padding-left: 3px;
	border-top: 2px solid #f6c952;
	font-size: 1rem;
	line-height: 1.5rem;
}

.right-sidebar-outer-wrap .box h4,
.right-sidebar-outer-wrap .box h3 {
	margin-top: 0.25em;
	margin-bottom: 0.5em;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-size: 1.3rem;
	line-height: 1.8rem;
	padding-top: 0.125em;
	font-weight: 700px;
}

.right-sidebar-outer-wrap .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar-outer-wrap .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-outer-wrap .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-outer-wrap .box.tools td:first-child img {
	margin-right: 5px;
}

@media (max-width: 991px) {
	.right-sidebar-outer-wrap .box {
		padding-right: 0px;
		padding-left: 0px;
		margin-right: 0px;
		margin-left: 0px;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.left-sidebar a,
.inside-row-nested a,
.cboxes a {
	border-bottom: 1px solid transparent;
	color: #263996;
	font-weight: 500;
}


.left-sidebar a:hover,
.inside-row-nested a:hover,
.cboxes a:hover {
	border-bottom-color: #797676;
	color: #263996;
}

h1 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

h2 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

h3 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

h4 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

h5 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

h6 {
	font-family: Roboto, sans-serif;
	color: #263996;
}

.left-sidebar h1 a,
.inside-row-nested h1 a,
.cboxes h1 a,
h1 a {
	border-bottom-color: transparent;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-weight: 700 !important;
}

.left-sidebar h2 a,
.inside-row-nested h2 a,
.cboxes h2 a,
h2 a {
	border-bottom-color: transparent;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-weight: 700 !important;
}

.left-sidebar h3 a,
.inside-row-nested h3 a,
.cboxes h3 a,
h3 a {
	border-bottom-color: transparent;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-weight: 700 !important;
}

.left-sidebar h4 a,
.main-content-section h4 a,
.right-nav-col h4 a,
.cboxes h4 a,
h4 a {
	border-bottom-color: transparent;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-weight: 700 !important;
}

.left-sidebar h5 a,
.main-content-section h5 a,
.cboxes h5 a,
h5 a {
	border-bottom-color: transparent;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-weight: 700 !important;
}

.left-sidebar h6 a,
.main-content-section h6 a,
.cboxes h6 a,
h6 a {
	border-bottom-color: transparent;
	font-family: Roboto, sans-serif;
	color: #263996;
	font-weight: 700 !important;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h1:hover a {
	border-bottom: 1px solid #797676;
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	padding: 0.65rem 1.5rem !important;
	border-style: solid;
	border-width: 1px;
	border-color: #263996 !important;
	background-color: #263996 !important;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Roboto, sans-serif;
	color: #fff !important;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	background-color: #ee1b2c !important;
	color: #fff !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	padding: 0.65rem 1.5rem !important;
	border-style: solid;
	border-width: 1px;
	border-color: #263996 !important;
	background-color: #fff !important;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Roboto, sans-serif;
	color: #263996 !important;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	background-color: #263996 !important;
	color: #fff !important;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	padding: 0.65rem 1.5rem !important;
	border-style: solid;
	border-width: 1px;
	border-color: #263996 !important;
	background-color: #fff !important;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Roboto, sans-serif;
	color: #263996 !important;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	background-image: url('/assets/images/ic-tertiary.jpg');
	background-repeat: no-repeat;
	background-position: left center;
	padding-left: 50px !important;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #ee1b2c !important;
	padding-left: 55px !important;
}

/* messages */
#message.success {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	padding: 1.25rem;
	background-color: #1f8927;
	color: #fff;
}

#message.error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #d11616;
	color: #fff;
}

#message.success p {
	font-family: Roboto, sans-serif;
	color: #fff;
	font-size: 1.05rem;
	line-height: 1.55rem;
	font-weight: 400;
}

#message.error p {
	font-family: Roboto, sans-serif;
	color: #fff;
	font-size: 1.05rem;
	line-height: 1.55rem;
	font-weight: 400;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

@media screen and (max-width: 767px) {
	#message.success p,
	#message.error p {
		font-size: 1rem;
	}
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: rgba(0, 0, 0, 0.15);
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid transparent;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: normal;
	text-transform: none;
	letter-spacing: 0;
	color: white;
	background-color: #373a3d;
	border-bottom: 2px solid white;
	padding: 0.625rem;
	text-align: left;
}

table.styled th {
	font-weight: normal;
	letter-spacing: 0;
	padding: 0.625rem;
	background-color: #263996;
	border-bottom: 0;
	color: white;
	text-align: left;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td,
td {
	padding: 0.375em 0.625rem;
}

table.styled.striped tr:nth-child(even) {
	background: #ebedf0;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 0.875rem;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	padding: 1.25rem 1.5rem;
	border-left: 5px solid #f6c952;
	color: #000;
	font-size: 1.25rem;
	line-height: 1.9rem;
	border-left-color: #f6c952;
	background-color: rgba(38, 57, 150, 0.15);
}

blockquote p {
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

@media (max-width: 767px) {
	blockquote {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.875em;
	line-height: 1.25em;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	font-weight: 700;
	text-transform: uppercase;
	border-bottom: 1px solid transparent;
	font-family: Roboto, sans-serif;
	color: #394486;
	font-style: normal;
}

/*********************** Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.cms-website-content-region table {
	width: 100%;
}

.cms-website-content-region table td {
	padding-bottom: 10px;
	padding-right: 14px;
	padding-left: 0px;
	padding-top: 0px;
	vertical-align: top;
}

@media screen and (max-width: 991px) {
	.copyright-text br {
		display: none;
	}

	.column.footer-signoff-column {
		width: 66.66666667%;
	}

	.column.footer-signoff-column.right-signoff-column {
		width: 33.33333333%;
		margin-bottom: 7px;
	}

	.row.footer-signoff-row {
		padding-left: 40px;
	}

	.column-3 {
		padding-left: 0px;
		justify-content: center;
		width: 100%;
		margin-top: 1rem;
		margin-bottom: 0;
	}
}

@media screen and (max-width: 767px) {
	.column-3 {
		margin-top: 0px;
	}

	.copyright-text br {
		display: none;
	}

	.column.footer-signoff-column {
		padding-right: 20px;
		padding-left: 30px;
	}

	.row.footer-signoff-row {
		padding-left: 40px;
		padding-right: 40px;
	}

	.column.footer-signoff-column {
		padding-right: 1rem;
		padding-left: 0;
		width: 100%;
		margin-top: 0px;
	}

	.column.footer-signoff-column.right-signoff-column {
		width: 100%;
		margin-bottom: 0px;
	}

	.footer-social-block.footer-block {
		flex-grow: 0;
		width: 50%;
		padding-left: 0;
	}

	.footer-social-block.footer-block.right-block {
		flex-grow: 0;
		width: 50%;
		padding-left: 0;
	}

	.column.footer-signoff-column.right-signoff-column {
		padding-right: 0;
		padding-right: 0;
	}

	.copyright-text {
		flex-grow: 0;
		width: 50%;
	}

	.footer-signoff-list {
		margin-right: -10px;
	}

	.footer-signoff-acrobat-link {
		width: 50%;
		text-align: left;
	}

	.column-3 {
		justify-content: flex-start;
	}

	.row.footer-signoff-row {
		padding-left: 10px;
		padding-right: 10px;
	}

	.cms-website-content-region table td {
		font-size: 1.05rem;
		line-height: 1.55rem;
	}
}

@media screen and (max-width: 479px) {
	.copyright-text {
		flex-grow: 0;
		width: 100%;
	}

	.column.footer-signoff-column.right-signoff-column {
		width: auto;
	}

	.footer-social-block.footer-block {
		justify-content: flex-start;
	}

	.footer-social-block.footer-block.right-block {
		margin-left: -22px;
	}

	.column.footer-signoff-column.right-signoff-column {
		padding-left: 0px;
		margin-left: -50px;
	}

	.column.footer-signoff-column {
		margin-top: 0px;
	}

	.cms-website-content-region table,
	.cms-website-content-region tr,
	.cms-website-content-region td,
	.cms-website-content-region tbody {
		display: block;
		width: 100%;
		flex-direction: column;
	}
}

/*********************** Modules ********************/
/*****************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

/* datepicker */
#ui-datepicker-div {
	font-size: 0.9rem;
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 5px;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	font-size: 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(92, 92, 92, 0.15);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: bold;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.75rem 1.25rem;
	margin-top: 10px;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* photo albums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide-caption {
	background-color: #666;
	font-family: inherit;
	color: #ffffff;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* posts */
.posts-node .posts-comments .required_information {
	color: #ee0000;
	font-style: italic;
}

.posts-collection > .node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
	padding-top: 0;
}

.posts-node #post-comments .post-comments-title {
	background-color: #394486;
	margin-top: 20px;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
	background-color: #fff;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.posts-blocks .posts-blocks-date-filter form.posts-blocks-date-filter-form select:last-of-type {
	margin-bottom: 10px;
}

.right-sidebar-column .box.posts-blocks.posts-blocks-rss-feeds a,
.right-sidebar-column .box.posts-blocks.posts-blocks-subscribes a {
	border-bottom: 0px;
}

.right-sidebar-column .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-sidebar-column .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-sidebar-column .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-sidebar-column .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-sidebar-column .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

.right-sidebar-column .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 8px 10px 7px 10px !important;
	background-color: #394486 !important;
}

.posts-node .posts-comments #post-add-comment .ui-form-buttons {
	text-align: center;
}

/* services */
#services_module.list .list_item .title h3 {
	margin-top: 0px;
}

#services_module.entity .image {
	float: none;
	margin: 0px 0px 10px 0px;
}

#services_module.list .list_item .footer {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	padding-bottom: 0px;
	margin-top: 0px;
}

#services_module.list .list_item {
	margin-bottom: 1.5rem;
	margin-top: 0.5rem;
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/* testimonials */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/******************* Content Boxes *******************/
/****************************************************/
/* submission forms */
.slider #submissionforms_module.homepage_contentbox {
	padding: 110px 20px 20px 40px;
}

#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
	padding-left: 0;
	padding-right: 0;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary {
	color: #394486 !important;
	background-color: transparent !important;
}

#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover {
	background-color: #394486 !important;
	color: #fff !important;
}

/* posts */
.cboxes .posts-collection .node:first-child {
	padding-top: 0px;
	margin-top: 0px;
	border: 0px;
}
